/* src/components/Header.css */
.header {
    background: linear-gradient(135deg, #4a90e2, #0052cc);
    color: #fff;
    padding: 40px 20px;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin: 20px auto;
    max-width: 90%;
  }
  
  .header-title {
    font-size: 2.5em;
    font-weight: bold;
    margin: 0;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  }
  
  .header-description {
    font-size: 1.2em;
    margin-top: 10px;
    color: #e0e7ff;
  }
  